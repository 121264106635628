body {
  margin: 0;
  padding: 0;
  background-color: #0E4772 !important;
  /* font-family: 'Sinkin Sans', sans-serif ; */
}

.rotate-infinite-slow {
  margin: auto !important;
}

.swal2-modal {
  text-align: left !important;
  padding: 30px !important;
}

.swal2-input {
  margin: 0 !important;
  width: 100% !important;
}

.swal2-title {
  margin: 0 0 20px 0 !important;
}

.current-value {
  font-weight: 500 ;
  font-size: 20px !important;
  fill: #28659C !important;
}

.segment-value {
  font-size: 20px !important;
  fill: white !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #6ABB47;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.teamActionButton {
  background-color: #428bca;
  border: 1px solid #357ebd;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  transition: 0.5s;
}

.addUserButton {
  cursor: pointer;
  color: #4172ac;
  font-size: 16px;
  font-weight: 600;
}

.addUserButton:hover {
  text-decoration: underline;
}

.teamActionButtonDisabled {
  opacity: 0.65;
  background-color: #428bca;
  border: 1px solid #357ebd;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  transition: 0.5s;
}

.teamActionButton:hover {
  background-color: #256ea5;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: rgb(240, 240, 240) !important;
}

.bs-tooltip-top {
  background-color: black !important;
  display: block !important;
  font-size: 20px !important;
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #7ACB59;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

#pie {
  height: 840px;
}

.dxc-legend {
  display: none !important;
}

/* html {
  font-size: 62.5%;
} */

.timelineBox {
  /* width: 350px; */
  display: flex;
  /* justify-content: center; */
  margin-top: 30px;
}

.timelineBody {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  /* width: 350px; */
}

.timeline {
  padding: 3rem 2rem !important;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  z-index: 2;
}

.timeline:before {
  content: '';
  position: absolute;
  width: 4px;
  background: #fff;
  top: 0;
  right: auto;
  left: 25px;
  height: 100%;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.085), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.timeline li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  position: relative;
}

.timelineDot {
  height: 1.5rem;
  /* width: 1.5rem; */
  background: #fff;
  border-radius: 2rem;
  flex: 0 0 1.5rem;
  /* margin-right: auto; */
  margin-top: 6px;
  box-shadow: 0 0 8px #56cb5f;
}

.timelineDot::after {
  content: '';
  position: absolute;
  top: 9px;
  left: 3px;
  height: 9px;
  width: 9px;
  background: #56cb5f;
  border-radius: 50%;
}

.timelineDate {
  font-size: 1.2rem;
  flex: 0 0 80px;
  padding: 0.2rem;
}

.timelineWork {
  font-family: inherit;
  font-size: 1.4rem;
  margin-left: 30px;
}

.timelineWork small {
  display: block;
  color: #bdbdbd;
}

.timelineBody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.timelineBody::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.timelineBody::-webkit-scrollbar-thumb {
  background-color: #56CB5F;
}

/* switch button */

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 30px;
  background: rgb(194, 194, 194);
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 30px;
}
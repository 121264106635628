/* .modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}

.modal img {
  width: 100%;
  height: auto;
} */

.custom-toggler {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler-icon {
  align-items: center !important;
  width: 10px !important;
  padding: 5px !important;

}

.fullscreen-screenshot-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  cursor: pointer;
}

.ss-image {
  flex-direction: column;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
}

.modalImage {
  display: block;
  height: 90vh;
  width: 100%;
  margin: 0 auto;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

@font-face {
  font-family: "Euclid-light";
  src: url(./font/Euclid/Euclid\ Circular\ B\ Light.ttf);
}

@font-face {
  font-family: "Euclid-medium";
  src: url(./font/Euclid/Euclid\ Circular\ B\ Medium.ttf);
}

@font-face {
  font-family: "Euclid-regular";
  src: url(./font/Euclid/Euclid\ Circular\ B\ Regular.ttf);
}

@font-face {
  font-family: "Euclid-bold";
  src: url(./font/Euclid/Euclid\ Circular\ B\ Bold.ttf);
}

@font-face {
  font-family: "Euclid-semibold";
  src: url(./font/Euclid/Euclid\ Circular\ B\ SemiBold.ttf);
}

* {
  font-family: "Euclid-medium";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media only screen and (max-width: 991px) {
  #header-nav {
    display: none !important;
  }




  .how-it-works-container {
    justify-content: center !important;
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 1200) {
  #header-nav {
    display: none !important;
  }

  .how-it-works-container {
    justify-content: center !important;
    flex-direction: column !important;
  }


}

@media only screen and (min-width: 1200px) {

  .vertical-line {
    position: absolute;
    width: 2px;
    background-color: grey;
    /* top: 13%; */
    bottom: 0;
    /* display: none; */
    /* Centers vertically */
    height: 88%;
    left: -900px;
    margin-left: 800px;
    /* Adjust the left position as needed */
    transform: translateX(-50%);
  }

  .circle {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #7ACB59;
    background-color: white;
    margin-left: 800px;
    /* Centers vertically */

    left: -900px;
    transform: translateX(-50%);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
@import url('https://fonts.cdnfonts.com/css/sinkin-sans-400-regular');

.CircularProgressbar {
  width: 30px !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Euclid Circular A', sans-serif; */
  font-family: 'Sinkin Sans', sans-serif;

}

.loginButton {
  font-size: 18px;
  font-weight: 600 !important;
  color: #DBE3FC !important;
  border: 2px solid whitesmoke !important;
  margin-right: 10px;
}




.loginButton1 {
  font-size: 18px;
  font-weight: 600 !important;
  color: #DBE3FC !important;
  border: 2px solid whitesmoke !important;
  margin-right: 10px;
  background-color: #0d3756 !important;

}


.loginButton1:hover {
  background-color: #50AA00 !important;
  transition: 0.5s;

}


.signUpButton1 {
  font-size: 18px;
  font-weight: 600 !important;
  color: white !important;
  border: none !important;
  background-color: #7ACB59 !important;
  /* background-color: #7ACB59 !important; */
}


.pricingButton:hover {
  background-color: #50AA00 !important;
  transition: 0.5s;
}

.pricingButton:disabled:hover {
  background-color: #ccc !important;
  /* transition: 0.5s; */
}

.pricingButton1:hover {
  background-color: #1d5b8a !important;
  transition: 0.5s;
}

.pricingButton1:disabled:hover {
  background-color: #ccc !important;
  /* cursor: not-allowed; */
}

/* .pricingButton2:hover {
  background-color: #ccd3d4 !important;
  transition: 0.5s;
} */

.pricingButton2:disabled:hover {
  background-color: #ccc !important;
  /* transition: 0.5s; */
  cursor: default;

}


.signUpButton1:hover {
  /* font-size: 18px;
  font-weight: 600 !important;
  color: white !important;
  border: none !important; */
  background-color: #50AA00 !important;
  transition: 0.5s;

  /* background-color: #7ACB59 !important; */
}

.signUpButton {
  font-size: 18px;
  font-weight: 600 !important;
  color: white !important;
  border: none !important;
  background-color: #7ACB59 !important;
}

nav {
  margin-top: 10px;
}

nav li a {
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 8px 35px !important;
}

.freePera {
  font-size: 45px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  margin-top: 60px;
}

.line {
  display: block;
  margin: 25px auto;
  width: 50%;
}

.logo {
  width: 260px;
  cursor: pointer;
  margin-left: 10px;
  /* margin-top: -70px; */
  /* margin-bottom: -30%;
  z-index: 40px !important; */
}

.logo1 {
  width: 330px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: -50px;
  /* margin-bottom: -30%;
  z-index: 40px !important; */
}


@media only screen and (max-width: 351px) {
  .logo {
    width: 160px !important;
  }

}

.navbar-nav {
  padding-right: -8px !important;
}

.mainFont {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.account {
  font-size: 26px;
  font-weight: 700;
  color: #0E4772;
  text-align: center;
  margin: 0;
}

.inputDiv {
  background-color: white;
  display: flex;
  /* justify-content: center; */
  margin: 20px auto;
  width: 100%;
  height: 55px;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #F8F8F8;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.inputDiv2 {
  /* background-color: white; */
  /* display: flex; */
  /* justify-content: center; */
  margin: 20px auto;
  width: 100%;
  height: 55px;
  /* align-items: center; */
  /* padding: 0 20px; */
  /* border: 1px solid #F8F8F8; */
  border-radius: 5px;
}

.inputDiv input {
  margin-left: 20px;
  border: 0px;
  background-color: white ;
  width: 100%;
}

.inputDiv input {
  outline: none;
}

.inputDiv input::placeholder {
  color: #A9ABBD;
  font-size: 16px;
  font-weight: 400;
}

.mainInputDiv {
  margin-top: 10px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  border-radius: 6px;
  width: 600px;
}

.checkboxDiv {
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
}

.checkboxDiv p {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
}

.checkboxDiv p span {
  color: #7ACB59;
}

.maininputdivs {
  display: flex;
  justify-content: center;
}

.checkboxDiv input.larger {
  width: 25px;
  height: 25px;
  background: #F0F2F1;
  border-radius: 3px;
}

.accountButton {
  width: 100%;
  height: 55px;
  text-align: center;
  background-color: #7ACB59;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: 0px;
  border-radius: 5px;
}


.accountButton1 {
  width: 102%;
  height: 55px;
  text-align: center;
  background-color: #7ACB59;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: 0px;
  border-radius: 5px;
}

.accountButton1:hover {
  background-color: #50AA00;
  transition: 0.5s;
}

.disabledAccountButton {
  width: 100%;
  height: 55px;
  text-align: center;
  background-color: #D0D7DE;
  font-size: 18px;
  font-weight: 600;
  border: 0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledAccountButton2 {
  background-color: #D0D7DE;
  border-radius: 5px;
  width: 200px;
  border: none;
  height: 45px;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.passwordButton {
  display: block;
  margin: 0 auto;
  width: 400px;
  height: 55px;
  text-align: center;
  background-color: #7ACB59;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: 0px;
  border-radius: 5px;
  margin-top: 30px;
}

.getback {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  color: #0E4772;
  /* margin-top: 50px; */
}

.loginFont {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-top: 25px;
}

.loginFont span {
  color: #7ACB59;
}

.lines {
  width: 30%;
  display: block;
  margin: 0px auto;
  /* margin-top: 200px; */
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footerLinks {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  /* width: 60%; */
}

.footerLinks p {
  /* margin: 0 15px; */
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  transition: 0.5s;
  justify-content: space-between;
  gap: 10px
}

.footerLinks p:hover {
  color: #50AA00;
}



.footerSocialMedia {
  /* width: 20%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px
}

.footerSocialMedia img {
  margin: 0 10px;

}


.accessFont {
  color: #0E4772;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  /* margin-top: 200px; */
}

.autofill:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s !important;
}

.liness {
  /* margin-top: 70px !important; */
  width: 30%;
  display: block;
  margin: 0px auto;
}

.remember {
  margin-bottom: 10px;
}

.remember p {
  margin-bottom: 0px;
}

.remember .forgot {
  color: #7ACB59;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}

.listDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* grid-template-columns: 45% 18%; */
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}

.listDiv .lists {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  padding-left: 0px;
  font-size: 20px;
  font-weight: 500;
  /* padding: 5px; */
  /* gap: 15px; */
}

.listDiv ul li span {
  margin-left: 10px;
}

.listDiv li {
  cursor: pointer;
}

.activeClass {
  background-color: #00000026 !important;
  padding: 10px 20px !important;
  border-radius: 6px !important;
}

/* .dropdown{
  background-color: #00000026;
  border: 0px;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;

}
.dropdown option{
  background-color: #00000026;
} */
/* .lists select option{
  background-color: #00000026 !important;
  border: 0px;
  color: white;


} */
.loginButton {
  font-size: 18px;
  font-weight: 500;
  color: #DBE3FC !important;
}

.loginButton:hover {
  background-color: #ede6e6 !important;
  transition: 0.5s;
}

.secondPart {
  display: grid;
  grid-template-columns: 52% 43%;
  margin-top: 100px;
  justify-content: space-between;
  padding-bottom: 100px;
  align-items: center;
}

/* .bannerDiv {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */

.greenBan {
  position: absolute;
  width: 600px;
  top: 52%;
  right: 10%;
}

/* .bannerDiv div{
  width: 200px;
  height: 200px;
} */
.banner {
  width: 100%;
  height: 100%;
}

.trackFont {
  font-size: 47px !important;
  color: #fff;
  font-weight: 600;
  line-height: 62px;
}

.secondPart p {
  margin-bottom: 0px;
}

.trackPera {
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  margin-top: 25px;
}

.trackPeras {
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  margin-top: 100px;
}

.start {
  width: 235px;
  margin-top: 50px;
}

.logoDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.logosDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.ethical {
  font-size: 35px;
  color: #0E4772;
  font-weight: 550;
  text-align: center;
  /* padding-top: 100px; */
}

.employee {
  font-size: 23px;
  color: #0E4772;
  font-weight: 400;
  text-align: center;
}

.cardSection {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 50px;
  margin-top: 75px;
  gap: 1rem;
}

.box {
  text-align: center;
  background-color: #0E4772;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.box:hover {
  background-color: #0E4772;
}

.trackEffort {
  font-size: 28px;
  font-weight: 600;
  margin-top: 20px;
}

.trackEffortPera {
  font-size: 18px;
  font-weight: 400;
  /* margin-top: 25px; */
}

.arrow {
  margin-top: 30px;
}

.pricing {
  background-color: #7ACB59;
  text-align: center;
  color: #ffffff;
  display: block;
  margin: 50px auto;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  width: 200px;
  font-size: 18px;
  font-weight: 600;
}

.secondSection {
  /* background-color: #fff; */
  padding: 50px;
}

.companyLine {
  width: 50%;
}

.dr {
  background-color: #00000026 !important;
}

.btn-group>.btn {
  background-color: #0E4772;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  width: 150px;
}

.btn-group>.btn:focus,
.btn-group>.btn:hover {
  background-color: #0E4772;
}

.dropdown-menu.show {
  background-color: #28659C;
  color: #fff !important;
  padding: 10px;
  font-family: 'Euclid Circular A', sans-serif;
}

.dropdown-item {
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.dropdown-divider {
  background-color: #ffffff;
}

.y8hr {
  width: 250px !important;
  background-color: #00000026 !important;
  padding: 10px 20px;
  border-radius: 6px;
  color: #7ACB59 !important;
}

.dropdown-toggle::after {
  color: white !important;
}

.menuitem {
  width: 250px;
}

.userName {
  font-size: 24px;
  color: white !important;
  background-color: #0D3756;
  border: 3px solid #fff;
  border-radius: 100%;
  font-weight: 500;
  width: 60px;
  height: 60px;
  margin: 0 0 0 10px;
}

.amButton {
  font-size: 22.33px;
  align-items: center;

  font-weight: 500;
  color: white;
}

.amButton p {
  margin-bottom: 0px;
  margin-left: 10px;
}

.bgColorChangeGreen> :not(:nth-child(odd)) {
  background-color: #EAF0F5;
  border-radius: 10px;
}

.dashheadings {
  display: grid;
  grid-template-columns: 15% 35% 12.5% 12.5% 12.5% 12.5%;
  align-items: center;
  padding: 10px;
  margin-bottom: 30px;
}

.dashsheadings {
  display: grid;
  grid-template-columns: 25% 25% 12.5% 12.5% 12.5% 12.5%;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.bgColorChange {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
}

.activeColorChange {
  background-color: #EFF9EC;
  border-radius: 10px;
  margin-top: 20px;

}

.dashheadingtop {
  /* font-family: 'Euclid Circular A'; */
  font-size: 14px;
  color: #0E4772;
  margin: 0;
  font-weight: 500;

}

.textalign {
  text-align: center;

}

.companyNameverified {
  display: flex;
  align-items: center;
}

.companyNameverified2 {
  display: flex;
  align-items: center;
}

.lastActive {
  cursor: pointer;
}

.dashCompanyName {
  /* margin: 0; */
  font-size: 25px;
  font-weight: 500;
  margin-left: 10px;
  color: #0E4772;
}

.screenShotPreview {
  width: 170px;
  object-fit: "contain";
  border: 5px solid #CCCCCC;
  border-radius: 5px;
  margin-right: 10px;
}

.screenShotAmount {
  /* font-family: 'Euclid Circular A'; */
  font-size: 18px;
  color: #7CCB58;
  text-align: center;
  margin: 0;
  font-weight: 600;

}

.userHeader {
  background-color: #28659C;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;

}

.userHeader h5 {
  margin: 0;
  margin-right: 30px;
  margin-left: 10px;
  color: #fff;
  font-size: 18px;
}

.headerTop {
  display: flex;
  align-items: center;
  background-color: #28659C;

}



.headerTop h6 {
  margin: 0;
  margin-right: 20px;

  color: #fff;

}

.headerTop p {
  color: #fff;
  margin: 0;
  margin-right: 20px;

}

.userMainContent {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;

}


.dayNamenDate {
  background: none;
  border: none;

}

.month {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.monthName {
  color: #28659C;
  margin: 0;
  margin: 0 20px;
  font-size: 20px;
  font-weight: 500;

}

.days-weeks {
  /* display: flex;
  justify-content: flex-start; */
  margin-top: 20px;
  width: 100%;
  overflow: hidden !important;
  scrollbar-width: none;
}

.day {
  border-right: 1px solid #EEEEEE;

}

.nonetaken {
  opacity: 0;
  margin: 0;
  font-size: 14px;

}

.weekName {
  text-align: center;
  font-size: 14px;
  margin: 0;

}

.Weekdate {
  font-size: 20px;
  text-align: center;
  background-color: #EFF9EC;
  color: #28659C;
  padding: 10px 8px;
  margin: 0;

}

.weekend {
  color: #FF0000;

}

.weekStart {
  border-left: 2px solid #28659C;

}

.weekdateActive {
  background-color: #0E4772;
  color: #fff;

}

.weekdayActive {
  background-color: #82B3DE;
  color: #fff;

}

.timerLeft {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2c3e50;
  background: -webkit-linear-gradient(to right, #2c7abe, #2c3e50);
  background: linear-gradient(to right, #2c7abe, #222f3c);
  border-radius: 10px;
  padding: 55px 35px;
  height: 327px;
}

.weekDayTimer {
  color: #ffffff;
  font-size: 23px;
  font-weight: 500;
  margin: 0;
  margin-right: 10px;

}

.weekDateTimer {
  color: #ffffff;
  font-size: 23px;
  font-weight: 500;
  margin: 0;
  margin-right: 10px;
}

.timerAndTracking {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3%;
}

.timerClock {
  width: 100%;
  font-size: 80px;
  font-weight: 400;
  /* text-align: right; */
  color: #7ACB59;
  margin: 0;
}

.weekTimer {
  color: #ffffff;
  font-size: 18px;
  margin: 0 5px;

}

.weekTimerDigit,
.monthTimerDigit {
  color: #50AA00;
  margin: 0 5px;
  font-size: 18px;
  font-weight: 500;
}

/* @media only screen and (max-width:1600px) {
  .activity-image-container {
    width: 100% !important;
    margin-top: 30px !important;
  }

  .activity-image {
    width: 430px !important;
  }
} */

/* .activity-image-container {
  position: relative;
  background-color: #EAF0F5;
  width: 100%;
  display: flex;
  justify-content: space-between;
} */

/* .activity-tracker-content {
  padding: 20px;
}

.activity-tracker-content p {
  font-size: 24px;
  font-weight: 600;
  color: #0D3756;
} */

/* .activity-image {
  width: 430px;
} */

/* .needle-container {
  position: absolute;
  bottom: 30px;
  left: 69.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.needle-container .dot {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #0D3756;
  border-radius: 100%;
}

.needle-container .needle {
  position: absolute;
  left: 5px;
  bottom: 40px;
  width: 10px;
  height: 100px;
  background-color: #0D3756;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transform-origin: bottom center;
  animation: rotateNeedle 10s infinite linear;
} */

/* @keyframes rotateNeedle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/* hassan code */

.activity-image-container {
  width: 50%;
  background-color: #F2FAEE;
  border-radius: 10px;
  padding: 20px;
  height: 327px;
}

.activityMainHeadingContent {
  font-size: 30px;
  color: #0D3756;
  font-weight: 600;
  margin: 0;

}

.activityMainContent {
  font-size: 24px;
  color: #0D3756;
  margin: 0;
  margin-top: 20px;

}

.activityMeternContent {
  display: flex;
  align-items: flex-start;

}

.activityMeterMain {
  width: 50%;

}

.activityMeterMainImage {
  width: 100%;

}

.activityMeterMainContainer {
  width: 100%;
  position: relative;

}

.needleScrewMain {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #0D3756;
  margin: 0 auto;

}

.needlePointerMain {
  width: 10px;
  height: 30px;
  background-color: #0D3756;
  margin: 0 auto;
  margin-bottom: -10px;

}

.needleContainerMainAlingment {
  display: flex;
  justify-content: center;

}

.needleContainerMain {
  position: relative;

}

.needleContainerMainAlingment {
  position: absolute;
  width: 20%;
  bottom: 0;
  left: 40%;
  /* transform: translateY(-50%); */
  transform-origin: 50% 78%;
  transition: 0.5s ease;
  /* transform: rotate(90deg); */
}

/* @media only screen and (max-width:1600px) {
  .needleContainerMainAlingment {
    bottom: 25px !important;
  }
} */

/* / customShape / */

.diamond {
  border-style: solid;
  border-color: transparent transparent #0D3756 transparent;
  border-width: 0 10px 15px 10px;
  height: 0;
  position: relative;
  margin-bottom: -10px;
  transform: rotate(180deg);
}

.diamond:before {
  content: "";
  position: absolute;
  top: 13px;
  left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #0D3756 transparent transparent transparent;
  border-width: 50px 15px 0 15px;

}


/* / CustomShape / */

.activityMeterContentMain {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 5px;
  margin-top: 10px;

}

.activityMeterContent {
  display: flex;
  align-items: center;
  width: 115px;

}

.activityMeterContentPercent {
  font-size: 18px;
  color: #0D3756;
  margin: 0;
  margin-left: 10px;

}

/* hassan code end */

.tasks {
  color: #28659C;
  text-align: right;
  margin: 0 5px;
  text-decoration: none;
}

.urls {
  color: #28659C;
  text-align: right;
  margin: 0 5px;
  text-decoration: none;

}

.taskLinks {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.linkActive {
  border-bottom: 2px solid #7ACB59;

}

.notes {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
  white-space: normal;
}

.projectTime {
  color: #fff;
  font-size: 18px;
  margin: 0;

}

.projectsAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #28659C;
  padding: 10px 20px 20px;
  margin-top: 20px;
  border-radius: 5px;

}

.timeClock {
  display: flex;
  align-items: center;
  margin-top: 2%;
  flex-wrap: wrap;

}

.timeOnClock {
  font-size: 17px;
  color: #0E4772;
  padding: 3px 17px 10px 3px;
  margin: 0;
  border: 1px solid #EAEAEA;
  background-color: #EFF9EC;

}

.activeBackground {
  background: linear-gradient(to right, #EFF9EC 60%, #A8C96A 40%);

}

.activeBackgroundEnd {
  background: linear-gradient(to right, #A8C96A 60%, #EFF9EC 40%);

}

.fullyActive {
  background-color: #A8C96A;

}

.firstRadius {
  border-radius: 5px 0 0 5px;

}

.lasRaidus {
  border-radius: 0 5px 5px 0;

}

.timeZone {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;

}

.timeDuration {
  color: #DB2B0B;
  font-size: 23px;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}

.projectName {
  color: #28659C;
  font-size: 18px;
  margin: 5px;
  /* font-weight: 500; */
  cursor: pointer;
  background-color: #A8C96A;
  padding: 0px;
  border: 1px solid greenyellow;
  border-radius: 5px;
}

.timeDuration:hover {
  text-decoration: underline;
}

.timeActivity {
  margin: 0 10px;

}

.screenShots {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 3%;
  width: 100%;
  align-items: center;
  /* flex-wrap: wrap; */

}

.screenShot {
  margin: 0;
  width: 100%;
  height: 420px;
  margin-top: 30px;

}

.timelineDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.websiteLink {
  text-decoration: none;
  color: white;
  margin-left: 10px;
}

.projectAdd {
  display: inline-block;
  margin: 20px 0 12px 0;
  background: #28659C;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  cursor: pointer;
  /* width: 30%; */
}

.delete {
  margin-right: 10px;

}

.screenShotImg {
  width: 100%;
  height: auto;
  background-color: #E8F4FC;
  /* padding: 9px 30px 17px 30px; */
  /* border-radius: 10px; */
  /* margin-top: 10px; */
}

.historyButton {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3%;

}

.historyOfChanges {
  color: #0E4772;
  margin: 0;
  margin-left: 10px;

}

/* Saved Reports */

.headings {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #E8F4FC;
  padding: 20px 30px;
  border-radius: 10px;

}

.heading {
  width: 25%;
  color: #0E4772;
  font-size: 20px;

}

.weeklyReportName {
  width: 25%;

}

.linksnNames {
  background-color: #fff;
  color: #0E4772;
  font-size: 18px;

}

.weeklyReportNames {
  margin: 0;

}

.weeklyReportLinkName {
  color: #0E4772;
  font-size: 20px;
  margin: 0;

}

.weeklyReportNamesLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}

.weeklyReportImmageLinks {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

}

.linkActionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #EFF9EC;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  margin: 0 auto;

}

.linkActionButtonText {
  margin: 0;
  margin-right: 10px;

}

.actionTextAlign {
  text-align: center;

}

/* Account Setting */

.userNamenCompany {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.userNameSetting {
  margin: 0;
  color: #0E4772;
  margin-right: 20px;

}

.companyName {
  margin: 0;
  color: #0E4772;
  margin-top: 50px;

}

.CompanyName {
  margin: 0;
  padding: 5px 10px;
  color: #0E4772;
  background-color: #EFF9EC;
  border-radius: 5px;

}

.email {
  color: #28659C;
  margin: 0;
  margin-top: 20px;

}

.time {
  margin: 0;
  color: #28659C;

}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3%;

}

.editButton {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 30px;

}

.iconsText {
  margin: 0;
  color: #28659C;
  font-weight: 500;
  font-size: 18px;
  margin-left: 10px;

}

.userDetailLine {
  display: block;
  margin: 0px auto;
  margin-top: 50px;
  width: 20%;
}

.userDasboardLine {
  display: block;
  margin: 0px auto;
  margin-top: 150px;
  /* width: 20%; */
}

.asadMehmood {
  font-size: 20px;
  font-weight: 600;
  color: #0E4772;

}

.asadMehmood span {
  background: #EFF9EC;
  border-radius: 3.90244px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 20px;
  margin-left: 5px;
}

.userEmail {
  font-size: 17.11px;
  font-weight: 400;
  color: #28659C;
}

.accountDiv {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 50px;

}

.accountEditDiv {
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #28659C;
  align-items: center;
  cursor: pointer;
}

.accountEditDiv p {
  margin-bottom: 0px;
}

.accountEditDiv p:hover {
  text-decoration: underline;
}

.accountContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 200px;

}

@media only screen and (max-width:991px) {
  .accountDiv {
    flex-direction: column !important;
    align-items: center;
  }

  .accountContainer {
    text-align: center;
  }

  .listDiv {
    flex-direction: column;
    justify-content: flex-start !important;
  }
}

.profileContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 50px;

}

.userDashboardContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
  /* padding-bottom: 200px; */
  margin-bottom: 50px;
}

.howItWorksSection {
  background-color: #fff;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  /* padding-bottom: 200px; */
  margin-bottom: 50px;
}

.userDashboardContainer1 {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px 10px 10px 10px;
  /* padding-bottom: 200px; */
  margin-bottom: 50px;
}


.companyPlan {
  font-size: 20px;
  font-weight: 600;
  color: #0E4772;
  margin-bottom: 10px;
  margin-top: 50px;

}

.accountLine {
  display: block;
  margin: 0px auto;
  margin-top: 50px;
  width: 30%;
}

.profileDiv {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-around;
  /* padding: 20px 100px; */

}

.profileInput {
  background-color: #E8F4FC;
  width: 90%;

}

.countryDropdown input {
  background-color: transparent;
  border: none;
  width: 80%;
  padding: 10px;
  color: #0E4772;
  font-size: 16px;
  /* height: '135%' */
}

.countryDropdownDisabled input {
  background-color: transparent;
  border: none;
  width: 80%;
  padding: 15px;
  color: #000000;
  font-size: 16px;
  /* height: 58px; */
}

.countryDropdown input[type="date" i] {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 10px;
}

.countryDropdown span {
  color: #7ACB59;
  padding: 0px 10px;
  font-weight: 500;
  font-size: 16px;
}

.countryDropdown input::placeholder {
  color: #0E4772;
  font-weight: 500;
}

.countryDropdown input:focus {
  outline: none;

}

.profileInput select {
  background-color: transparent;
}

.selectOption {
  background-color: transparent;
  border: none;
}

.countryDropdown {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #E8F4FC !important;
  border: none !important;
  color: #0E4772 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Euclid Circular A', sans-serif !important;
  margin-top: 10px;
  border-radius: 5px !important;
}

.countryDropdownDisabled {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  /* height: 40px; */
  height: 58px;
  background-color: #f0f0f0 !important;
  border: none !important;
  color: grey !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Euclid Circular A', sans-serif !important;
  margin-top: 10px;
  border-radius: 5.5798px !important;
}

.countryDropdown::after {
  color: #0E4772 !important;


}

.countryDropdownmenu.show {
  background-color: #E8F4FC !important;

  width: 90%;

}

.countryItem {
  color: #0E4772 !important;
  font-size: 16px !important;
  font-weight: 500 !important;

}

.countryLabel {
  font-size: 20px;
  font-weight: 600;
  color: #0E4772;
  margin-top: 25px;
}

.saveCancelButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}

.saveCancelButton p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  color: #0E4772;
}

.saveButton {
  background: #6ABB47;
  border-radius: 5px;
  width: 200px;
  border: none;
  height: 45px;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.profileGoBackButton {
  background: #6ABB47;
  border-radius: 5px;
  width: 100px;
  border: none;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.cancelButton {
  background: #0E4772;
  border-radius: 5px;
  width: 200px;
  border: none;
  height: 45px;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.profileLine {
  margin: 0px auto;
  display: block;
  margin-top: 50px;
  width: 30%;
}

.summaryContainer {
  background-color: #fff;
  padding: 30px 50px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 50px;


}

.reportdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  color: #0E4772;
  padding: 20px 50px;
  background: #E8F4FC;
  border-radius: 5px;
}

.reportdiv p {
  margin-bottom: 0;
}

.reportLinkDiv {
  width: 35%;
  display: flex;
  justify-content: space-between;


}

.weeklyReportDiv {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  font-size: 18px;
  font-weight: 500;
  color: #0E4772;

}

.reportsDiv {
  display: flex;
  width: 37%;
  justify-content: space-between;
}

.reportImageDiv {
  display: flex;
  gap: 20px;
  /* align-items: center; */
}

.actionDiv {
  display: flex;
  width: 35%;
  justify-content: space-between;
}

.reportImagesDiv {
  display: flex;
  align-items: center;
  gap: 20px;
}

.editButton {
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: space-evenly;
  border: none;
  background: #EFF9EC;
  border-radius: 5px;
  padding: 5px;

}

.summaryLine {
  display: block;
  margin: 0 auto;
  width: 40%;
  margin-top: 150px;
}

.hideDiv {
  display: none;
}

.onlineWorker {
  font-size: 24px;
  font-weight: 400;
  color: #7CCB58;
  padding: 0px 15px;
}

.employeeDashboardContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
  /* min-height: 60vh; */
}

.employeeDashboardLine {
  width: 30%;
  display: block;
  margin: 0px auto;
  margin-top: 40px;

}

.calenderDiv {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.calenderInnerDiv {
  display: flex;
  /* width: 50%; */
  gap: 50px;
  justify-content: space-between;
}

.dayDiv {
  display: flex;
  gap: 20px;
  justify-content: space-between;

}

.dayDiv p {
  margin-bottom: 0px;
  color: #4172AC;
  font-size: 13px;
}

.dateDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.dateDiv button {
  background-color: #F5F5F5;
  border: none;
  padding: 10px 0px;
  justify-content: space-between;
  border-radius: 5px;
}

.utc5 {
  background-color: transparent !important;
  border: none !important;
  color: #0E4772 !important;

}

.utc5::after {
  /* background-color: transparent !important; */
  /* border: none; */
  color: #0E4772 !important;

}

.utc5Menu {
  background-color: transparent !important;
  /* border: none !important; */

}

.utc5MenuItem {
  color: #0E4772 !important;
}

.crossButton {
  background-color: #FFFFFF;
  color: #28659C;
  border: 1.30556px solid #28659C;
  border-radius: 2.61111px;
  padding: 1px 10px;
}

.crossButtonDiv {
  /* display: flex; */
  /* justify-content: space-between; */
  /* background-color: #E6F1F9; */
  /* padding: 10px; */
  /* border-radius: 5px; */
  margin: 20px 0;
}

.downArrowDiv {
  display: flex;
  gap: 10px;
}

.projectFont {
  font-size: 16px;
  color: #4172AC;
  font-weight: 500;
}

.summaryUrl {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #0E4772;
}

.summaryButton {
  text-align: end;
  margin-top: 50px;
  font-size: 18px;
  font-weight: 400;
  color: #0E4772;
}

.summaryButton button {
  background: #E6F1F9;
  border-radius: 5.22222px;
  border: none;
  color: #0E4772;
  padding: 10px;
  width: 180px;
}

.summaryButton button.activeButton {
  background-color: #0E4772;
  color: white;
}

.summaryUrl p {
  margin-bottom: 0px;
}

.adminReportHour {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.adminReport4 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.adminReport4 img {
  width: 500px;
  height: 300px;
}

.sixtyhour {
  font-size: 62px;
  font-weight: 500;
  color: #7ACB59;
  margin: 0;
  text-align: center;
}

.report-percentage {
  font-size: 21px;
  font-weight: 500;
  color: #0E4772;
  margin: -6px 0 0 0;
  text-align: center;
}

.dollar102 {
  font-size: 21px;
  font-weight: 500;
  color: #0E4772;
  text-align: center;
}

.percent {
  color: #50AA00;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.employeeDiv {
  display: flex;
  justify-content: space-between;
  background-color: #28659C;
  color: #fff;
  align-items: center;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 50px;
}

.asadMehmoodDiv {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1 !important;
  align-items: center;
  padding: 20px 30px;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
}

.asadMehmoodkabhaiDiv {
  display: flex;
  justify-content: space-between;
  background-color: #dff4f5 !important;
  align-items: center;
  padding: 10px 30px 10px 70px;
  border-radius: 5px;
  /* border: 1px solid #797979; */
  margin-top: 3px;
  /* cursor: pointer; */
}

.asadMehmoodDiv span {
  margin-left: 10px;
}

.employeeDiv p {
  margin-bottom: 0px;
  font-size: 21px;
  font-weight: 500;
}

.asadMehmoodDiv p {
  margin-bottom: 0px;
  font-size: 21px;
  font-weight: 500;
  color: black;

}

.asadMehmoodkabhaiDiv p {
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 500;
  color: black;
}

.durationDiv {
  display: flex;
  justify-content: space-evenly;
  width: 300px
}

.durationDiv p {
  margin: 0;
}

.admin1Line {
  width: 30%;
  display: block;
  margin: 0px auto;
  margin-top: 50px;

}

.groupDiv {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* input.checkboxLarge{
  width: 26px;
  height: 26px;
 
} */

.inputCheckboxDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}

/* input[type=checkbox]:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  border: 1px solid #0E4772;
  border-radius: 3px;
  padding: 1px;
  background-color: #E6F1F9;
} */

.checkBoxDiv Label {
  margin-left: 10px;
}

input[type=checkbox]:checked:before {
  background-color: #6ABB47;
}

.inputCheckbox {
  display: flex;
  gap: 20px;
  /* align-items: center; */
}

.checkBoxDiv {
  display: flex;
  gap: 20px;
  align-items: center;
}

.inputCheckbox p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #4172AC;
}

.excelDiv {
  display: flex;
  gap: 20px;
}

.excelInnerDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.excelInnerDiv p {
  margin-bottom: 0px;
}

.homeSection {
  background-color: white;
  width: auto;
  overflow: hidden;
  /* height: 100vh; */
}

.firstSection {
  background-color: #0E4772;
}

.thirdSection {
  background-color: #0E4772;
  padding: 50px 0px;
}

.thirdSection1 {
  padding: 50px 20px;
  font-size: 16px;
}


.millionHours {
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.joinButton {
  text-align: center;
  color: white;
  background-color: #7ACB59;
  display: block;
  margin: 0 auto;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px;
}

.feedbackDiv {
  display: grid;
  /* grid-template-columns: 33% 33% 33%;
  justify-content: space-around;
  margin-top: 50px; */

}

.olivia {
  display: block;
  margin: 0 auto;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.oliviafont {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
}

.oliviaGreen {
  font-size: 16px;
  font-weight: 400;
  color: #7ACB59;
  text-align: center;

}

.oliviaPera {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.oliviaDiv {
  display: block;
  margin: 0 auto;
  width: 64%;
  margin-top: 20px;
}

.feedbackBox {
  margin-top: 30px;
}

.leftRightArrow {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

}

.leftRightArrow p {
  margin-bottom: 0px;
  color: white;
}

.trialDiv {
  background-color: #FFFFFF;
  border-radius: 16px;
  padding: 40px 29px;
  margin-top: 50px;
}

.freeTrialDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.startedFont {
  font-size: 30px;
  font-weight: 600;
  color: #0E4772;
  /* margin: 0 !important; */
}

.unitedFont {
  /* width: 800px; */
  font-size: 18px;
  font-weight: 500;
  color: #0E4772;
  margin: 0 !important;
}

.learnMoreButton {
  background-color: transparent;
  border: 1px solid #0E4772;
  border-radius: 8px;
  color: #0E4772;
  padding: 8px 10px;
}

.startedButton {
  color: white;
  background-color: #7ACB59;
  border-radius: 8px;
  padding: 8px 10px;
  border: none;
}

.startedButtonDiv {
  display: flex;
  gap: 10px;
}

.deanDiv {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;

}

.deanBanner {
  width: 100%;
}

.employetime {
  font-size: 34px;
  font-weight: 600;
  color: #0E4772;
}

.managerFont {
  font-size: 23px;
  font-weight: 400;
  color: #0E4772;
}

.fourSection {
  background-color: #E8F4FC;
  margin-top: 70px;
  padding: 50px;
}

.employees {
  font-size: 23px;
  color: #0E4772;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0px;
}

.employees p {
  margin-bottom: 0px;
}

.employeesDiv {
  width: 55%;
  display: block;
  margin: auto;
}

.reportsimage {
  width: 100%;
  display: block;
  margin: 0 auto;
  filter: drop-shadow(-40px 40px 250px rgba(30, 33, 50, 0.13));
  border-radius: 20px;
  margin-top: 100px;

}

.fiveSection {
  margin-top: 100px;
  padding: 40px;
}

.sss div {
  width: 50%;
}

/* .reportImage{
  width: 100%;
  display: block;
  margin: auto;
} */

.wifi {
  display: block;
  margin: 0 auto;
}

.internetFont {
  font-size: 26px;
  font-weight: 600;
  color: #0E4772;
  text-align: center;
  margin-top: 20px;
}

.internetPera {
  font-size: 20px;
  font-weight: 400;
  color: #0E4772;
  margin: 0;
  text-align: center;
}

.eightSection {
  background-color: #0E4772;
  padding: 50px;
  margin-top: 50px;
}

.employeeTracking {
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}

.startnowButton {
  background-color: #7ACB59;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  border: 3px solid #dee3e0;
  padding: 8px 10px;
  border-radius: 5.52px
}


.startnowButton:hover {
  background-color: #50AA00;
  transition: 0.5s;
}

.creditCancel {
  font-size: 16px;
  color: #FFFFFF80;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
}

.homeLine {
  display: block;
  margin: 0 auto;
  /* margin: 40px auto; */
}

.reportButton {
  margin-top: 40px;
}

.adminTeamContainer {
  background-color: #fff;
  padding: 30px 50px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 50px;


}

.searchInput {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1.30556px solid #28659C;
  border-radius: 5.22px;
  width: 350px;
  padding: 5px 10px;
  gap: 10px;
}

.searchInput img {
  width: 20px;
}

.searchInput input {
  border: none;
  width: 100%;

}

.searchInput input::placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #0E4772;
}

.searchInput input:focus {
  outline: none;
}

.settingContainer {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 200px;
}

.settingMainDiv {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.screenshotButton {
  display: flex;
  justify-content: space-between;
  width: 421px;
  align-items: center;
  margin-bottom: 0px;
  height: 57px;
  border: none;
  background-color: transparent;
  padding: 5px 10px;
  margin-top: 10px;
}

.screenshotButton:hover {
  background-color: #f1f2f6;
  border-radius: 5.22px;
  /* padding: 5px 10px; */
  border-left: 10px solid #aad490;
}

.adminTeamEmployess {
  display: flex;
  justify-content: space-between;
  width: 350px;
  align-items: center;
  margin-bottom: 0px;
  height: 57px;
  border: none;
  background-color: transparent;
  padding: 5px 10px;
  margin-top: 10px;

  /* margin-right: 10px; */
  cursor: pointer;
}

.adminTeamEmployess:hover {
  background-color: #f1f2f6;
  border-radius: 5.22px;
  border-left: 10px solid #aad490;
}

.activeEmploy {
  background-color: #f1f2f6;
  border-radius: 5.22px;
  border-left: 10px solid #aad490;
}

.screenshotButton p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  color: #404040;
}

.hour12 {
  color: #6ABB47 !important;
  font-size: 20px;
  font-weight: 500 !important;
}

.activeButtonClass {
  background-color: #f1f2f6;
  border-radius: 5.22px;
  border: none;
  padding: 5px 10px;
  border-left: 10px solid #aad490;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 417px;
  height: 57px;
  margin-top: 10px;
}

.activeButtonClass p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  color: #282828;
}

.settingScreenshotHeading {
  font-size: 30px;
  font-weight: 600;
  color: #0E4772;
  margin-bottom: 0;
}

.settingScreenshotDiv {
  background-color: #EFF9EC;
  color: #0E4772;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px;
  /* height: 102px; */
}

.takeScreenShotDiv span {
  font-size: 17px;
  color: #515151;
  font-weight: 400;

}

.takeScreenShotDivs span {
  font-size: 17px;
  color: #515151;
  font-weight: 400;

}

.componentScreenshot {
  width: 100%;
}

.settingScreenshotDiv p {
  margin-bottom: 0px;

}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: #0E4772;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 1px;
  top: 1px;
  width: 18px;
  height: 18px;
  border: 2px solid green;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: green;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;

}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.myselect {
  border: 2px solid #b8b8b8;
  border-radius: 5px;
  color: #000000;
  font-weight: 500;
  padding: 4px;
  width: 100%;
}

.takeScreenShotDiv {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.takeScreenShotDiv p {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
  color: #0E4772;
}

.takeScreenShotDivs p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  color: #0E4772;
}

.settingScreenshotIndividual {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 600;
  color: #0E4772;
}

.individualSettingFont {
  font-size: 16px;
  font-weight: 400;
  color: #6ABB47;
}

.employee-individual-setting {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}

/* @media only screen and (max-width: 1600px) {
  .employee-individual-setting {
    gap: 10px;
  }
} */

.newDiv {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  margin-top: 20px;
  border-bottom: 2px solid #dbdbdb;
  padding-bottom: 10px;
}

.newDiv p {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  color: #7e7e7e;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0E4772;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 8px;
  width: 9px;
  height: 16px;
  border: solid green;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.takeScreenShotDivs {
  display: flex;
  align-items: center;
  gap: 10px;

}

.tabish {
  font-size: 20px;
  font-weight: 400;
  color: #515151;
}

.horizontallines {
  border: 1px solid black;
}

.horizontallines1 {
  border: 2px solid black;
  opacity: 0px !important;
}

.settingActivityDiv {
  background-color: #EFF9EC;
  color: #0E4772;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px;
  /* height: 102px; */
}

.settingActivityDiv p {
  margin-bottom: 0px;
}

.activityLevelDiv {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 30px;
}

.trackLevelDiv {
  display: flex;
  align-items: center;
  gap: 50px;

}

.activityLevelIndividual {
  margin-top: 30px;
}

.number {
  border: 2px solid #b8b8b8;
  border-radius: 5px;
  color: #000000;
  font-weight: 500;
  padding: 4px;
  width: 60px;
}

.number::placeholder {
  color: #0E4772;
}

.number:focus {
  outline: none;
}

.mydayselect {
  width: 173px;
  height: 45px;
  margin-top: 20px;
  border: 2px solid #0E4772;
  border-radius: 2px;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #515151;
  text-align: center;
  /* display: flex; */
  /* justify-content: center; */
}

.dollar {
  margin-top: 20px;
  width: 70px;
  border: 2px solid #0E4772;
  color: #0E4772;
  padding: 5px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
}

.searchGroupnEmployee {
  display: flex;
  align-items: center;
  border: 1px solid #0E4772;
  border-radius: 5px;
  padding: 8px;
  width: 421px;

}

.searchIconMain {
  width: 20px;

}

.searchIconMain img {
  width: 100%;

}

.searchMainInput {
  border: none;
  margin-left: 15px;
  width: 100%;
  outline: none;

}

.createUserGroup {
  margin-top: 20px;

}

.createUserButton {
  background: none;
  border: none;
  color: #0E4772;
  font-size: 16px;
  font-weight: 500;


}

.infoHover {
  background: none;
  border: none;
  margin-left: 10px;
  width: 15px;

}

.infoHover img {
  width: 100%;

}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

.groupContentMain {
  background: none;
  border: none;
  display: flex;
  width: 100%;
  /* margin-top: 10px; */
  padding: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.groupContentMain:hover {
  background-color: #E6F1F9;
  border-radius: 5px;
  border-left: 5px solid #28659C;
  padding-left: 5px;
}

.groupContentMainImg {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: white;
  border: 1px solid rgb(185, 185, 185);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.groupContentMainImg p {
  margin: 0;
}

.archive-users-section button {
  background-color: grey !important;
  color: white;
  font-weight: 600;
}

.archive-user {
  color: grey !important;
}

.groupContentMainImg img {
  width: 100%;

}

.groupContent {
  font-size: 20px;
  font-weight: 500;
  color: #181717;
  margin: 0;
  margin-left: 10px;
}

.inviteFormInput {
  background: none;
  border: none;
  outline: none;
  padding: 10px;
  border: 1px solid #28659C;
  border-radius: 5px;
  width: 314px;
  margin-right: 10px;

}

.inviteFormInput::placeholder {
  color: #B7C8D5;

}

.inviteButton {
  background: none;
  border: none;
  padding: 10px 20px;
  background-color: #6ABB47;
  color: #fff;
  border-radius: 5px;
  width: 108px;

}

.inviteForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

}

.tabishDiv {
  /* background-color: #E6F1F9; */
  border-radius: 5.22px;
  border: none;
  padding: 5px 10px;
  /* border-left: 5px solid #28659C; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 417px;
  height: 47px;
  margin-top: 20px;
  gap: 20px;

}

.tabishDiv p {
  margin-bottom: 0px;
  font-size: 20px;
  color: #0E4772;
}

.buttonOne {
  width: 30px;
  border: none;
  background-color: #6ABB47;
  height: 30px;
  border-radius: 18px;
  color: white;
  font-size: 12px;
}

.activeButtonClasss {
  background-color: #E6F1F9;
  border-radius: 5.22px;
  border: none;
  padding: 5px 10px;
  border-left: 5px solid #28659C;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 417px;
  height: 47px;
  margin-top: 20px;
}

.namenDeleteMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.nameContent {
  font-size: 40px;
  color: #0E4772;
  font-weight: 500;
  margin: 0;

}

.pauseDeleteMain {
  display: flex;
  align-items: center;

}

.pauseMain {
  display: flex;
  align-items: center;
}

.archiveMain {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.pauseMain p,
.archiveMain p,
.deleteMain p {
  font-size: 16px;
  font-weight: 500;
}

.pauseMain p:hover,
.archiveMain p:hover,
.deleteMain p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pauseContent {
  background-color: rgb(40, 101, 156);
  border: 1px solid #357ebd;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  transition: 0.5s;
}

.pauseContent:hover {
  background-color: #256ea5;
}

.paueIcon {
  margin-right: 5px;

}

.rightSideDiv {
  width: 100%;
}

.eamilnCountryMain {
  display: flex;
  align-items: center;
  margin-top: 20px;

}

.emailContent {
  font-size: 16px;
  color: #0E4772;
  font-weight: 400;
  margin: 0;

}

.countryFlag {
  width: 20px;
  margin-left: 10px;

}

.flagIcon {
  width: 100%;

}

.amountnChangeButtonMain {
  display: flex;
  align-items: center;
  margin-top: 10px;

}

.amountContent {
  color: #0E4772;
  font-size: 16px;
  font-weight: 500;
  margin: 0;

}

.changeContent {
  background: none;
  border: none;
  color: #6ABB47;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  margin-left: 20px;

}

.viewTimelineContent {
  background: none;
  border: none;
  color: #6ABB47;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;

}

.roleContentMain {
  margin-top: 20px;

}

.roleHeadingContent {
  margin: 0;
  font-size: 30px;
  color: #0E4772;
  font-weight: 500;
  margin-bottom: 20px;

}

/* RADIO BUTTON */
.radio-button [type="radio"]:checked,
.radio-button [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radio-button [type="radio"]:checked+label,
.radio-button [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-size: 20px;
  color: #0E4772;
}

.bulletInfo {
  color: #515151;
  font-size: 16px;
  font-weight: 400;

}

.radio-button [type="radio"]:checked+label:before,
.radio-button [type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #6ABB47;
  border-radius: 100%;
  background: #fff;
}

.radio-button [type="radio"]:checked+label:after,
.radio-button [type="radio"]:not(:checked)+label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #6ABB47;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio-button [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-button [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* radio button end */

.userGroupsMain {
  margin-top: 20px;

}

.projectsGroupsMain {
  margin-top: 40px;

}

.userGroupsMain .roleHeadingContent {
  margin: 0;

}

.projectsGroupsMain .roleHeadingContent {
  margin: 0;

}

.userGroupsMain .viewTimelineButtonMain {
  display: flex;
  align-items: center;

}

.userGroupsMain .viewTimelineButtonMain .viewTimelineContent {
  margin-left: 20px;

}

.projectsGroupsMain .viewTimelineButtonMain .viewTimelineContent {
  margin-left: 20px;

}

.userGroupsMain .viewTimelineButtonMain .addAll {
  margin-left: 0;

}

.projectsGroupsMain .viewTimelineButtonMain .addAll {
  margin-left: 0;

}

/* toggle start */

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

.toggleContent:checked+.slider {
  background-color: #66bb6a;
}

.toggleContent:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.groupLabel {
  margin-left: 75px;

}

.groupLabel {
  font-size: 20px;
  color: #515151;
  font-weight: 500;
  width: 300px;
  margin-top: 3px;

}

.toggleMainContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 30px;

}

.toggleMain {
  width: 30%;
  margin-top: 30px;

}

.settingsMainContent .roleHeadingContent {
  margin-top: 50px;
  margin-bottom: 20px;

}

.settingheading {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 20px;

}

.settingheadingMain {
  margin: 0;
  font-size: 20px;
  color: #515151;
  font-weight: 500;
  width: 350px;

}

.settingButton {
  background: none;
  border: none;
  color: #6ABB47;
  font-size: 20px;
  font-weight: 500;

}

/* team end */

/* setting start */

.groupContentSetting2 {
  color: #6ABB47;
  font-size: 16px;
  font-weight: 400;
  margin: 0;

}

.groupContentSetting {
  margin: 0;
  font-size: 16px;

}

.groupContentMainSetting {
  background: none;
  border: none;
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding: 10px;

}

.groupContentMainSetting:hover {
  background-color: #E6F1F9;
  border-radius: 5px;
  border-left: 5px solid #28659C;
  padding-left: 5px;

}

.settingLeft .groupContentMainSetting {
  justify-content: space-between;
  align-items: center;

}

.settingLeft {
  width: 35%;

}

.groupContentMainActive {
  background-color: #E6F1F9;
  border-radius: 5px;
  border-left: 5px solid #28659C;
  padding-left: 5px;

}

.nameContent {
  font-weight: 400;

}

.settingSubPara {
  background-color: #E6F1F9;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;

}

.settingSubParaMain {
  color: #0E4772;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  line-height: 36px;

}

/* dropdown */

/*Styling Selectbox*/
.dropdown {
  /* display: inline-block; */
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(14, 71, 114);
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
  /* margin: 0 10px; */
}

.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 5px 10px;
}

.dropdown .select>i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all .3s ease-in-out;
  line-height: 20px;
  margin-left: 10px;
}

.dropdown:hover {
  box-shadow: 0 0 4px rgb(204, 204, 204)
}

.dropdown:active {
  background-color: #f8f8f8
}

.dropdown.active:hover,
.dropdown.active {
  box-shadow: 0 0 4px rgb(204, 204, 204);
  border-radius: 2px 2px 0 0;
  background-color: #f8f8f8
}

.dropdown.active .select>i {
  transform: rotate(-90deg)
}

.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 1px 2px rgb(204, 204, 204);
  border-radius: 0 1px 2px 2px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9
}

.dropdown .dropdown-menu li {
  padding: 10px;
  transition: all .2s ease-in-out;
  cursor: pointer
}

.dropdown .dropdown-menu {
  padding: 0;
  list-style: none
}

.dropdown .dropdown-menu li:hover {
  background-color: #f2f2f2
}

.dropdown .dropdown-menu li:active {
  background-color: #e2e2e2
}

.roleContentMain .settingbutton {
  display: flex;
  align-items: center;

}

.roleContentMain .settingbutton p {
  margin: 0;

}

.userGroupsMain .viewTimelineButtonMain .viewTimelineContent {
  font-weight: 400;

}

.individualsettingContent {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #B3B3B3;
  padding-bottom: 10px;

}

.individualsettingContent .settingbutton {
  justify-content: flex-end;

}

.userGroupsMain .settingsHeading {
  margin-top: 70px;

}

.ml {
  margin-left: 30px !important;

}

.hoursLimit {
  outline: none;
  background: none;
  border: none;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 5px 10px;
  width: 70px;

}

/* setting end */

/* my account start */

.userNameHeadingContent {
  margin: 0;
  color: #0E4772;
  font-size: 20px;
  font-weight: 500;

}

.userNameHeading {
  display: flex;
  align-items: center;

}

.companyTagContent {
  margin: 0;
  color: #0E4772;
  margin-left: 10px;
  border-radius: 5px;
  padding: 5px 20px;
  background-color: #EFF9EC;
  font-size: 16px;

}

.emailContentMain {
  margin-top: 40px;

}

.emailContentMain p {
  color: #28659C;
  font-size: 16px;
  margin: 0;

}

.myAccountButtons .pauseDeleteMain {
  margin-top: 50px;

}

.myAccountButtons .pauseDeleteMain .pauseContent {
  color: #28659C;

}

.companyPlanHeading {
  color: #0E4772;
  margin: 0;
  margin-top: 50px;

}

.companySubPara {
  color: #28659C;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10%;

}

/* my account end */

/* saved reports start */

.savedReportHeadings .userNameHeading {
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #E8F4FC;
  width: 100%;

}

.savedReportHeadings .companyTag {
  width: 25%;

}

.savedReportHeadings .userNameHeadingContent {
  font-size: 18px;

}

.savedReportLinks .userNameHeading {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 30px;
  width: 100%;

}

.companyTagLink {
  width: 25%;
  display: flex;
  align-items: center;

}

.copyIconMain {
  width: 20px;
  margin-left: 10px;

}

.copyIconContent {
  width: 100%;

}

.savedReportLinks .companyTag {
  width: 25%;

}

.userNameParaContent {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #0E4772;

}

.userNameLinkContent {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #0E4772;

}

.userNameEditButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  background-color: #EFF9EC;
  color: 0E4772;
  padding: 10px 30px;
  border-radius: 5px;

}

.companyLinkButton {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.editButtonIconMain {
  width: 15px;
  margin-left: 10px;

}

.editButtonIconContent {
  width: 100%;

}

.screenShotButtonMain {
  height: 25px;

}

.screenShotButtonContent {
  height: 100%;

}

.screenShotButtonMainMargin {
  margin: 0 10px;

}

.userGroupMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.projectContainer {
  background-color: #fff;
  padding: 30px 50px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 200px;


}

.addEmployee {
  width: 314px;
  height: 47px;
  padding: 5px;
  text-align: center;
  background: #FFFFFF;
  border: 1.30556px solid #28659C;
  border-radius: 5.22px;
  /* opacity: 0.3; */
}

.addEmployee::placeholder {
  color: #0E4772;
  font-weight: 400;
  font-size: 20px;
  opacity: 0.3;
}

.projectTabishDiv {
  /* background-color: #E6F1F9; */
  border-radius: 5.22px;
  border: none;
  padding: 5px 10px;
  /* border-left: 5px solid #28659C; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 417px;
  height: 47px;
  /* margin-top: 20px; */
  gap: 10px;
}

.groupProject {
  display: flex;
  /* align-items: center; */
  font-size: 20px;
  font-weight: 400;
  color: #50AA00;
  gap: 10px;
}

input.checkboxes:checked {
  background-color: #000000 !important;

}

.administration {
  font-size: 40px;
  font-weight: 400;
  color: #0E4772;
  margin-left: 30px;

}

.displayLabel {
  display: flex;
  align-items: center;
}

.projectRightDiv {
  width: 100%;
}

.clientMaindiv {
  background-color: #E6F1F9;
  width: 100%;
  display: flex;

  text-align: center;
  height: 173px;
  justify-content: center;
  margin: auto;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 400;
  color: #0E4772;
  line-height: 36px;
}

.inviteForms {
  margin-top: 20px;
}

.inviteForms input {
  width: 85%;
}

.inviteForms button {
  width: 14%;
}

.projectline {
  margin-top: 10 0px;
}

.screenshotiimage {
  width: 100%;
  height: 100%;
}

.deleteAccountApi {
  background-color: white;
  position: absolute;
  height: 200px;
  width: 400px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  text-align: center;
  border-radius: 5px;
  padding: 45px;
  left: 40%;

}

.deleteAccountApi p {
  font-size: 30px;
  margin-bottom: 0px;
  color: #0E4772;
  /* display: block; */
  /* margin: auto; */
}

.deleteCancelButton {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  font-size: 20px;
}

.deleteApiButton {
  color: white;
  background: #0E4772;
  width: 100px;
  height: 32px;
  border: none;
  border-radius: 5px;
}

.cancelApiButton {
  color: #0E4772;
  background: white;
  width: 100px;
  height: 32px;
  border: none;
  border-radius: 5px;
  border: 1px solid #0E4772;
}

.updateAccountApi {
  background-color: white;
  position: absolute;
  height: 200px;
  width: 400px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  text-align: center;
  border-radius: 5px;
  padding: 45px;
  left: 40%;

}

.updateAccountApi input {
  height: 40px;
  width: 200px;
  text-align: center;

}

.logoutDiv {
  position: absolute;
  background-color: #28659C;
  top: 70px;
  width: 194px;
  /* height: 100%; */
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  border-radius: 6px;
  right: 10px;
  z-index: 1;
  padding: 20px 0;
}

.logoutDiv>div {
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding: 10px 30px;
  transition: 0.5s;
  /* align-items: center; */
}

.logoutDiv p {
  margin: 0;
}

.logoutDiv>div:hover {
  background-color: #0E4772;
}

.timelineInput {
  padding: 5px;
  background: #FFFFFF;
  border: 1.30556px solid #28659C;
  border-radius: 5.22px;
}

.showmore {
  color: #50AA00 !important;
  font-size: 20px;
  font-weight: 600;
}

.showDropdown.show {
  display: grid !important;
  grid-template-columns: 32% 32% 32%;
  width: 700px;
  border-radius: 10px;
  background-color: #28659C !important;
  padding: 10px 10px !important;

}

.showDropdown input {
  border: none;
  background-color: transparent;
}

.showDropdown input:focus {
  outline: none;
}

.showDropdown.show li {
  padding: 10px 10px;
  font-weight: 500;
  font-size: 24px !important;
  display: flex;
  gap: 10px;
  color: #fff !important;
}

.dropdown-item-list {
  color: #fff;
  text-decoration: none;

}

.priceInput {
  border: 2px solid #0E4772;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
}

.changeCurrency {
  background-color: #0E4772;
  color: white;
  border: none;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
}

/* .editSettings{
  margin-left: 10px;
} */
.header {
  display: flex;
  align-items: center;
}

.days {
  display: flex;
}

.css-13cymwt-control {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  border: none !important;
  color: #0E4772 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Euclid Circular A', sans-serif !important;
  /* margin-top: 10px; */
  border-radius: 5.5798px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.css-t3ipsp-control {
  outline: none;
  height: 55px;
}

.css-13cymwt-control:focus {
  outline: none;
}

.active {
  border: 3px solid #28659C !important;
}

.active2 {
  border: 3px solid #59ae2c !important;
  /* background-color: #59ae2c; */
  /* color: #fff; */
}

.editBoxMainDiv {
  display: flex;
  justify-content: center;
}

.editBox {
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 11.1596px;
  /* width: 100%; */
  color: #0E4772;
  box-shadow: 0px 0px 5px 1px rgb(205, 205, 205);
  /* background-color: #000000; */
  /* padding: 20px; */
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.editBoxUpperDiv {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  background-color: #EFF9EC;
  padding: 20px;
  font-size: 15px !important;
  color: #0E4772;
  font-weight: 500;

}

.editBoxUpperDiv h1 {
  font-size: 30px;
  font-weight: 500;
}

.editBoxUpperDiv button {
  border: none;
  background-color: transparent;

}

.editBoxUpperDiv button img {
  width: 15px;

}

.editBoxLowerDiv .editboxinputdiv input {
  width: 100px;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
}

.editBoxLowerDiv .editboxinputdiv {
  display: flex;
  gap: 15px;
  align-items: center;
}

.editBoxLowerDiv .editboxinputdiv p {
  margin-bottom: 0px;
  font-size: 20px;
}

.editBoxLowerDiv {
  width: 100%;
  /* padding: 30px; */
}

.projectOption {
  width: 100%;
  height: 60px;
  color: #0E4772;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 500;
  padding: 10px 20px;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.projectOption:focus {
  outline: none;
}

.sevenAm {
  color: #82B3DE;
  margin-bottom: 0px;
  margin-top: 10px;
  font-weight: 700;
}

.editBoxLowerDiv textarea {
  width: 90%;
  margin-top: 20px;
  padding: 20px;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
}

.deleteActivityPart {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.editSaveChanges {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.saveChangesButton {
  color: #fff !important;
  background-color: #0E4772 !important;
  border-color: #357ebd;
}

.cancelChangesButton {
  color: #0E4772 !important;
  background-color: #E8F4FC !important;
  border-color: #357ebd;
}

.editBoxLowerDiv a {
  text-decoration: underline !important;
  font-weight: 700;
}

.teamDiv {
  position: absolute;
  background-color: #28659C;
  top: 200px;
  width: 194px;
  height: 182px;
  padding: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  border-radius: 6px;
  right: 10px;
  z-index: 1;
  left: 38%;
}

.teamDiv div {
  cursor: pointer;
}

.systemAdminContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 200px;

}

.searchLogo {
  width: 25px;

}

.searchDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 8px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.add-user-div {
  display: flex;
  align-items: center;
  gap: 10px;
  /* border: 1px solid #0E4772; */
  border-radius: 5px;
  padding: 18px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 30px;
}

.searchDiv input,
.add-user-div input {
  border: 0;
  outline: none;
}

.set-payrate-div {
  display: flex;
  align-items: center;
  gap: 10px;
  /* border: 1px solid #0E4772; */
  border-radius: 5px;
  padding: 18px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* margin-top: 30px; */
}

.set-payrate-div input {
  border: 0;
  outline: none;
}

.companyFont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  /* width: 100% !important; */
}

.add-users {
  font-size: 20px;
  color: #0E4772;
  font-weight: 600;
}

.archiveFont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.ownerFont {
  font-size: 22px;
  color: #0E4772;
  font-weight: 500;
  text-transform: capitalize;
}

.ownerFont span {
  font-weight: 400;

}

.ownerEmail {
  text-transform: lowercase;
}

.even-background {
  background-color: #E6F1F9;
  color: #0E4772;
  /* Set the text color for better visibility */
  /* Add any other styling you want */
}

.odd-background {
  background-color: #D5E7F5;
  color: #0E4772;
  /* Add any other styling you want */
}

.mainBackground {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 20px;
  padding: 15px;
  text-transform: capitalize;
  font-weight: 500;
  border-left: 6px solid #0E4772;
}

.mainBackground p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.mainBackground img {
  width: 25px;
}

.disableCompanies {
  /* background-color: lightgray; */
  /* color: bal; */
  color: #0E4772;
  padding: 5px;
  font-size: 20px;
  border-top: 2px solid #0E4772;
  border-bottom: 2px solid #0E4772;
  /* background-color: #E6F1F9; */
}

.disableCompanyFont {
  color: #86A3B8;
  font-size: 20px;
  color: #0E4772;
  margin: 0;
  opacity: 0.7;

}

.systemAdminLogoutDiv {
  position: absolute;
  background-color: #28659C;
  top: 63px;
  width: 150px;
  height: 100px;
  padding: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  border-radius: 6px;
  right: 150px;

  z-index: 1;
}

.systemAdminLogoutDiv>div {
  display: flex;
  gap: 10px;
  cursor: none;
  /* align-items: center; */
}


.ownerSectionFont {
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;

}

.ownerSectionUser {
  padding: 2px 12px !important;
  border-radius: 6px !important;
  color: #7ACB59;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}

.ownerSectionUser1 {
  padding: 8px 10px !important;
  /* border-radius: 6px !important; */
  color: #7ACB59;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}

.ownerSectionUser1:hover {
  /* padding: 8px 10px !important;
  border-radius: 6px !important; */
  color: #7ACB59 !important;
  /* font-size: 17px;
  font-weight: 500;
  cursor: pointer; */
  transition: 1s;
}



.user-active-tabs {
  padding: 10px 20px !important;
  border-radius: 6px !important;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}


.active-tab-2 {
  background-color: #7FC45B !important;
  /* padding: 2px 2px 0px 2px !important; */
  padding: 8px 5px !important;
  border-radius: 6px !important;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.active-tab {
  background-color: #7FC45B !important;
  /* padding: 2px 2px 0px 2px !important; */
  padding: 5px !important;
  border-radius: 6px !important;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.active-tab1 {
  background-color: #FFF !important;
  /* padding: 2px 2px 0px 2px !important; */
  /* padding: 10px 20px !important; */
  border-radius: 6px !important;
  color: white;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}

.ownerSectionCompany {
  background-color: #28659C !important;
  padding: 9px 10px !important;
  border-radius: 6px !important;
  color: #7ACB59;
  /* width: 200px; */
  justify-content: center;
  font-size: 17px;
  gap: 5px;
  /* cursor: none; */

}

.ownerSectionCompany1 {
  /* background-color: #28659C !important; */
  /* padding: 10px !important; */
  border-radius: 6px !important;
  color: #7ACB59;
  /* width: 200px; */
  justify-content: center;
  font-size: 17px;
  gap: 23px;
  /* cursor: none; */

}

.systemOwnerContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
  /* padding-bottom: 200px; */
  /* margin-bottom: 50px; */
  /* min-height: 80vh; */
  /* margin-bottom: ; */
}

.ownerCount {
  background-color: #EFF9EC;
  padding: 10px 10px;
  color: #0E4772;

  font-size: 24px;
  font-weight: 600;
  border-radius: 5px;

}

.ownerCount p {
  margin-bottom: 0px;
}

.ownerInput {
  background-color: #EFF9EC;
  padding: 15px;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
}

.ownerInput input {
  border: none;
  outline: none;
  background-color: transparent;
}

.ownerCompanySignupDiv {
  display: flex;
  justify-content: center;
  width: 50%;
  background-color: #FFFFFF;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 20px;
  border-radius: 5px;
}

.ownerInput input::placeholder {
  color: #0E4772;
  font-size: 17px;
}

.ownerSignUpButton {
  width: 100%;
  margin-top: 20px;
  border: 0;
  background-color: #50AA00;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
}

.ownerTeamContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 0 0 10px 10px;
  /* width: 350px; */
  /* padding-bottom: 200px; */
  /* margin-bottom: 50px; */
}

.roundedButton {
  background-color: #50AA00 !important;
  border: none !important;
  width: 40px;
}

.employeeDetail {
  font-size: 22px;
  font-weight: 500;
  color: #0E4772;
  margin: 0;
}

.employeeDetailName1 {
  color: #0E4772;
  font-size: 28px !important;
  font-weight: 600 !important;
  margin: 0;
}

.employeeDetailName2 {
  color: #0E4772;
  font-size: 22px !important;
  font-weight: 500 !important;
}

.employeeDetailName3,
.employeeDetailName4,
.employeeDetailName5,
.employeeDetailName6 {
  margin: 0;
  font-size: 16px;
}

.employeePayrate {
  color: #0E4772;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 0;
}

.profileWidth {
  width: 40%;

}

/* .summaryDiv {
  background-color: #8BAFC0;
  height: 300px;
  width: 700px;
  opacity: 0.5;
} */

.summaryDiv canvas {
  width: 300px;
  height: 300px;
}

.summaryDiv p {
  font-size: 24px;
  color: #0E4772;

}

.summaryTodayDiv p {
  cursor: pointer !important;
}

.summaryTodayDiv p:hover {
  text-decoration: underline;
}

.backgroundColor {
  background-color: #50AA00;
}

.timeline {
  background-color: #EFF9EC;
}

.downloadButton {
  background-color: #50AA00;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  width: 200px;
  text-align: center;
  border: none;
  border-radius: 5px;

}

.time-scale {
  margin-top: 50px;
  overflow-x: scroll;



}

.time-interval {
  color: #EFF9EC !important;
}

.minute-container {
  display: flex !important;
  font-size: 1px;
  height: 20px;
}

.hour-color {
  /* background-color: #EFF9EC; */
  border: 1.5px solid #e3e3e3;
  text-align: center;
  color: #0E4772;
}

/* Customize the scrollbar for webkit-based browsers */
.time-scale::-webkit-scrollbar {
  width: 10px;
  /* Set the width of the scrollbar */
  height: 10px;
  margin-top: 20px;
}

.time-scale::-webkit-scrollbar-track {
  background: #EFF9EC;
  /* Background color of the scrollbar track */
}

.time-scale::-webkit-scrollbar-thumb {
  background: #0E4772;
  /* Color of the scrollbar thumb (the draggable part) */
  border-radius: 5px;
  /* Rounded corners for the thumb */
}

.time-scale::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the thumb on hover */
}

.contact-us-container {
  display: grid;
  align-items: center;
  grid-template-columns: 45% 45%;
  justify-content: center;
  align-items: flex-start;
  /* margin: 30px 100px 0 100px; */
}

.roundedButton {
  background-color: #50AA00 !important;
  border: none !important;
  width: 40px;
}

.employeeDetail {
  font-size: 22px;
  font-weight: 600;
  color: #0E4772;
}

.employeeDetailName {
  color: #0E4772;
  font-size: 18px;
  font-weight: 500;
}

.profileWidth {
  width: 40%;

}

/* .summaryDiv {
  background-color: #8BAFC0;
  height: 300px;
  width: 700px;
  opacity: 0.5;

} */

.summaryDiv p {
  font-size: 24px;
  color: #0E4772;

}

.summaryTodayDiv p {
  cursor: default;
}

.backgroundColor {
  background-color: #50AA00;
}

.timeline {
  background-color: #EFF9EC;
}

.downloadButton {
  background-color: #7ACB59;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  width: 200px;
  text-align: center;
  border: none;
  border-radius: 5px;

}


.downloadButton:hover {
  background-color: #50AA00;
  transition: 0.5s;
}


.signUpButton:hover {
  background-color: #50AA00 !important;
  transition: 0.5s;
}

/* Contact us section */

.contactus {
  margin-top: 100px;
}

.listDiv {

  display: flex;
  width: 50%;
  margin: 0 auto;
  margin-top: 100px;
}

.lightGreen {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 50px;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.formFullDiv {
  width: 90%;
}

.fullForm {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 2%;
  /* display: block; */
  /* justify-content: center; */
  /* flex-direction: column; */
  gap: 45px
}

.firstName {
  background-color: whitesmoke;
  padding: 10px;
  /* border-radius: 10px; */
  border: #EAFAF3;
  /* margin-left: 20px; */
  width: 90%;
  border-radius: 10px;
  outline: none;
  gap: 55px;
  display: flex;

}


.firstNameHead {
  /* margin-left: 20px; */
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 18px;
  color: #10462D;
}

.firstFormPart {
  padding: 40px 0;
}


.message {
  /* margin-left: 20px; */
  border: none;
  width: 185%;
  border-radius: 10px;
  padding: 15px;
  outline: none;
}

.contactUs {
  font-weight: 600;
  font-size: 45px;
  color: #0E4772;
}

.query {
  font-weight: 600;
  size: 24px;
  color: #0E4772;
}

.formButton {
  display: block;
  width: 130px;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 18px !important;
  font-weight: 500 !important;
  background-color: #7ACB59 !important;
  border: none !important;
}


.publicRelation {
  /* background-image: url("images/activity-tracking.jpg"); */
  width: 100%;
  height: 100%;
  background: #0E4772;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px;
  padding-left: 10%;
  /* padding-bottom: -50px; */
}

.contact-img {
  justify-content: end;
}


.halfPart {
  margin-top: 20px;
  color: white;
}

/* Download page css */

.download-container {
  margin: 100px 0;
}

.download-title {
  text-align: center;
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.download-card-header {
  height: 100px;
  padding: 20px 40px;
  background-color: rgb(225, 225, 225);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
}

.download-card-header p {
  font-weight: 500;
  font-size: 25px;
  margin: 0;
}

.download-main-section {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  /* width: 100%; */
  margin: 30px 200px 0 200px;
  /* display: ; */
  border-radius: 30px;
}

.download-action-container {
  /* margin: 30px; */
  width: 500px;
  background-color: rgb(225, 225, 225);
  border-radius: 30px;
  padding: 30px;
  height: 100%;
}

.download-action-container p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.download-button {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  border: none;
  width: 240px;
  /* max-width: 100%; */
  border: none;
  /* color: white; */
  border-radius: 5px;
  padding: 10px;
  margin-left: -8px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  /* background-color: #0E4772 */
}

.disable-download-button {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  border: none;

  width: 240px;
  /* max-width: 100%; */
  border: none;
  color: #0E4772;
  border-radius: 5px;
  padding: 10px;
  margin-left: -8px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background-color: #ccc !important;
}


.download-card {
  display: flex;
  justify-content: space-around;
  padding: 40px;
}



@media only screen and (max-width:600px) {
  .download-action-container {
    /* margin: 30px; */
    width: 100px;
    background-color: rgb(225, 225, 225);
    border-radius: 30px;
    padding: 30px;
    height: 100%;
  }
}

@media only screen and (max-width:1200px) {
  .download-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .download-action-container {
    /* margin: 30px; */
    width: 600px;
    border-radius: 30px;
    padding: 30px;
    height: 100%;
  }
}

@media only screen and (max-width:1400px) {
  .download-main-section {
    margin: 30px 80px 0 80px;
  }

  .download-action-container {
    /* margin: 30px; */
    width: 554px;

    border-radius: 30px;
    padding: 30px;
    height: 53%;
  }
}

/*rks section */

.how-it-works-container {
  /* width: 100%; */
  /* margin: 30px 100px 0 100px; */
  border-radius: 30px;
}

.how-it-works-title {
  font-size: 35px;
  font-weight: 600;
  color: #0E4772;
}

.features-title {
  font-size: 25px;
  font-weight: 600;
  color: #0E4772;
  margin: 30px 0 10px 0;
}

.features-working {
  font-size: 18px;
  font-weight: 500;
  color: #0E4772;
  width: 800px;
}

.feedbackDiv {
  /* display: grid; */
  grid-template-columns: 33% 33% 33%;
  justify-content: space-around;
  gap: 0 20px;
}

/* .olivia {
  display: block;
  margin: 0 auto;
  width: 55px;
} */

.oliviafont {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.oliviaGreen {
  font-size: 16px;
  font-weight: 700;
  color: #7ACB59;
  text-align: center;
  margin: 0 0 10px 0;
}

.oliviaPera {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #1d1c1c;
  margin: 0;
}

.oliviaDiv {
  display: block;
  margin: 0 auto;
  /* width: 64%; */
  margin-top: 20px;
}

.feedbackBox {
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.feedbackBox1 {
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #0E4772;
  border-radius: 20px;
  padding: 20px;
}

@media screen and (max-width : 1600px) {
  .container {
    max-width: 1200px !important;
    /* padding:; */
  }
}

@media screen and (max-width: 1780px) {
  .NavBarBottomLine {
    display: none;
  }

  .days-weeks {
    overflow-x: scroll !important;
  }
}

@media screen and (max-width : 1600px) {
  .container {
    max-width: 1200px !important;
    /* padding:; */
  }
}

@media screen and (max-width: 1780px) {
  .NavBarBottomLine {
    display: none;
  }

  .days-weeks {
    overflow-x: scroll !important;
  }
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  -webkit-appearance: none;
  appearance: none;
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.disabledinput {
  background-color: red !important;
}

.add-user-btn {
  font-size: 16px;
  font-weight: 600 !important;
  color: white !important;
  border: none !important;
  background-color: #7ACB59 !important;
  width: 130px;
  height: 50px;
  border-radius: 6px;
}

.send-invite-btn {
  font-size: 15px;
  font-weight: 600 !important;
  color: white !important;
  border: none !important;
  background-color: #7ACB59 !important;
  width: 100px;
  height: 40px;
  border-radius: 6px;
}

.input {
  width: 130px;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  text-align: center;
  font-size: 36px;
  cursor: pointer;
  pointer-events: all;
}

/* input[type=text] {
  margin: 0 10px;
} */

.input:focus {
  border-bottom: 3px solid #7FC45B;
  outline: none;
}

.verification-code {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}

.verification-code-sent {
  font-weight: 500;
  font-size: 18px;
  color: grey;
}


/* Ensure input is displayed in 24-hour format */
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.time-input-wrapper {
  position: relative;
}

.time-input-wrapper input[type="time"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100px;
  /* Adjust the width as needed */
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.time-input-wrapper::before {
  content: attr(data-time);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
  color: #aaa;
}

.time-input-wrapper input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}


.container {
  position: relative;
  width: 100%;
}

.timeline {
  position: relative;
}

.vertical-line {
  position: absolute;
  width: 2px;
  background-color: grey;
  top: 13%;
  bottom: 0;
  margin-left: 44%;
  /* Centers vertically */
  height: 88%;
  left: 100px;
  /* Adjust the left position as needed */
  transform: translateX(-50%);
}

.circle {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #7ACB59;
  background-color: white;
  margin-left: 44%;
  /* Centers vertically */

  left: 100px;
  transform: translateX(-50%);
}

/* @media (max-width: 768px) {
  .vertical-line {
    position: absolute;
    left: -110px;
    margin-left: '90%';
    height: 100%;
    top: '15%';
    transform: translateX(-50%);
  }

  .circle {
    left: -110px;
    top: -16px;
    transform: translateX(-50%);
  }


} */

/* Optional: For better alignment, you can adjust the margins */
.col-4 {
  position: relative;
  z-index: 2;
}




.price-container {
  display: flex;
  flex-direction: column;
  /* Stack prices vertically */
  align-items: center;
  /* Center align items horizontally */
  font-size: 1.5em;
  /* Adjust font size as needed */
}

.old-price {
  position: relative;
  /* Required for positioning pseudo-element */
  color: black;
  /* Text color */
  font-size: 2rem;
  /* Smaller font size for old price */
  text-align: center;
  /* Center align text */
  margin-bottom: 5px;
  /* Space between old and new prices */
  flex-direction: column;
  /* Stack prices vertically */
  align-items: center;
  /* Center align items horizontally */
}

.old-price::after {
  content: '';
  position: absolute;
  left: -5px;
  top: 60%;
  width: 140%;
  height: 3px;
  background-color: darkred;
  /* Darker red color for the strikethrough */
  background-color: red;
  /* Strikethrough color */
  transform: rotate(-15deg);
  /* Rotate the line to create a slant */
  transform-origin: bottom left;
  /* Rotate from the bottom left corner */
}


.free-price::after {
  content: '';
  position: absolute;
  left: -5px;
  top: 60%;
  width: 140%;
  height: 3px;
}


.new-price {
  color: red;
  /* Text color for new price */
  font-weight: bold;
  /* Bold text for new price */
  text-align: center;
  /* Center align text */
  font-size: 2rem;
}

.small-dollar {
  font-size: 0.7em;
  /* Smaller font size for the dollar sign */
  vertical-align: middle;
  /* Align vertically centered */
}




.small-number {
  font-size: 0.8em;
  /* Smaller font size for the number */
  vertical-align: middle;
  /* Align vertically centered */
}




.pricingButton,
.pricingButton1 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin: auto;
  padding: 8px 10px;
  border-radius: 5.52px
}

.pricingButton2 {
  color: #ccc;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin: auto;
  padding: 8px 10px;
  border-radius: 5.52px;
  background-color: #ccc !important
}



.activtiyUrl::after {
  background-color: red;
  /* Strikethrough color */
  transform: rotate(-3deg);
  /* Rotate the line to create a slant */
}



@media only screen and (max-width: 990px) {
  .insideCardSS {
    width: 235px !important;
  }

  .footerImg {
    margin-left: 30% !important;
  }

  .footerLink {
    margin-right: 36% !important;
  }
}

@media only screen and (max-width: 908px) {
  .insideCardSS {
    width: 200px !important;
  }

  .footerImg {
    margin-left: 30% !important;
  }

}


@media only screen and (max-width: 788px) {
  .insideCardSS {
    width: 220px !important;
  }

  .footerImg {
    margin-left: 41% !important;
  }

  .footerLink {
    margin-right: 35% !important;
  }

  .card-title,
  .card-text {
    text-align: center !important;
  }

  .downloadButton {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 36%;
  }

}


@media only screen and (max-width: 440px) {
  .insideCardSS {
    width: 120px !important;
    height: 50px !important;
  }

  .logo {
    width: 180px !important;
  }

  .footerImg {
    margin-left: 39% !important;
  }

  .footerLink {
    margin-right: 30% !important;
  }

  .downloadButton {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 23%;
  }

}

@media only screen and (max-width: 348px) {
  .insideCardSS {
    margin-top: -5%;
    width: 100px !important;
    height: 60px !important;
  }

  .downloadButton {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 14%;
  }

  .logo {
    width: 130px !important;
  }

  .footerImg {
    margin-left: 37% !important;
  }

  .footerLink {
    margin-right: 37% !important;
  }

}


@media only screen and (max-width: 292px) {
  .insideCardSS {
    margin-top: -10%;
    width: 90px !important;
    height: 40px !important;
  }
}


@media only screen and (max-width: 271px) {
  .insideCardSS {
    margin-top: -18%;
    width: 70px !important;
    height: 30px !important;
  }
}


@media only screen and (max-width: 300px) {
  .insideCardSS {
    margin-top: -18%;
    width: 80px !important;
    height: 50px !important;
  }

}

@media only screen and (max-width: 302px) {
  .insideCardSS {
    margin-top: -6%;
    width: 60px !important;
    height: 40px !important;
  }

  .footerImg {
    margin-left: 30% !important;
  }

  .footerLink {
    /* margin-right: 35% !important; */
  }
}

/* payment */
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.payment-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.success-message {
  color: green;
  margin-bottom: 10px;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #218838;
}


/* payment */

.crossButton {
  border: 5px solid black !important;
  width: 20px;
  /* adjust the width and height to your liking */
  height: 20px;
  cursor: pointer;
  /* change the cursor shape on hover */
  transition: transform 0.2s;
  /* add a transition effect */
}

.hayat .card {


  width: 40rem;


}

.hayat {


  align-items: center;
  justify-content: center;

}

@media only screen and (max-width: 1200) {
  .card {
    width: 50%
  }
}

@media (max-width: 1600px) and (min-width: 1100px) {
  .hayat {
    /* width: max-content; */
  }

  .hayat .card {
    width: 50%;
    border: 0;
  }



}

/* @media (max-width: 1100px) and (min-width: 300px) {


  .mobhayat {

    inline-size: fit-content;

  }


} */


@media (max-width: 1100px) and (min-width: 300px) {


  .mobhayat {

    inline-size: fit-content;

  }

  body {

    width: fit-content;

  }

}

/* @media (max-width: 1000px) and (min-width: 300px) {


  .mobhayat {

    inline-size: fit-content;

  }

  body {

    width: fit-content;

  }

} */


/* Remove arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.custom-modal-width .modal-dialog {
  max-width: 700px;
  /* Adjust the width as needed */
  width: 100%;
}

/* Hide the dotted line on smaller screens */
@media (max-width: 768px) {
  .dotted-line {
    display: none;
  }
}

/* Hide the dotted line on medium and smaller screens */
@media (max-width: 992px) {
  .dotted-line {
    display: none;
  }
}

/* Hide the dotted line on medium and smaller screens */
@media (max-width: 1383px) {
  .dotted-line {
    display: none;
  }
}


@media (max-width: 768px) {

  /* Tablets and Mobile */
  .platform-icon {
    width: 35px !important;
    /* Shrinks the icons */
    height: auto !important;
  }
}

@media (max-width: 576px) {

  /* Small Mobile Screens */
  .platform-icon {
    width: 140px !important;
    /* Makes icons even smaller */
  }
}

/* Media query: Reduce icon size for screens 578px and below */
@media (max-width: 575px) {
  .platform-icon {
    max-width: 85px !important;
    /* Reduce size for smaller screens */
  }
}

/* Default for large screens */
.background-container {
  border-radius: 40%;

}

/* For screens 578px and smaller, remove border-radius */
@media (max-width: 578px) {
  .background-container {
    border-radius: 25% !important;
    width: 150% !important;
  }


}

@media (max-width: 768px) {
  .platform-icon {
    /* border-radius: 25% !important; */
    width: 80% !important;
  }

  .platform-icon1 {
    /* border-radius: 25% !important; */
    width: 20% !important;
  }
}

@media (max-width: 575px) {
  .background-container {
    border-radius: 25% !important;
    width: 250% !important;
  }

}

/* For screens 500px and smaller, increase the height */
@media (max-width: 500px) {
  .background-container {
    height: 200% !important;
    /* Adjust the height as per your need */
    border-radius: 50% !important;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 400px;
    /* Reduce height for tablets */
    background-size: contain;
    /* Prevent cropping */
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 300px;
    /* Reduce height further for mobile */
    background-size: contain;
    /* Ensure full image visibility */
    background-position: center center;
  }
}

@media (max-width: 768px) {
  .responsive-section h2 {
    font-size: 22px;
    /* Adjust text size for smaller devices */
  }

  .responsive-section img {
    width: 20%;
    /* Smaller image for mobile */
  }
}

.responsive-image {
  display: block;
  /* Default to display the image */
}

@media (max-width: 1024px) {
  .responsive-image {
    /* display: none; */
    /* Hide image on all mobile screen sizes */
  }

}

/* Default width for overlay-content */
.overlay-content {
  width: 70%;
}

/* Remove width on screens smaller than 768px */
@media (max-width: 768px) {
  .overlay-content {
    width: 100%;
    /* Set width to full or remove it completely */
  }
}




@media (max-width: 1300px) and (min-width: 1200px) {
  .company-container {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .company-text {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }

  .ownerSectionUser {
    padding: 7px !important;
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .company-container {
    /* flex-direction: column; */
    align-items: center;
    text-align: center;
  }

  .company-logo {
    width: 30px;
    height: 30px;
  }

  .company-text {
    max-width: 150px;
    font-size: 20px;
  }
}

@media (max-width: 1100px) {
  .company-container {
    /* flex-direction: column; */
    align-items: center;
    text-align: center;
  }

  .company-logo {
    width: 30px;
    height: 30px;
  }

  .company-text {
    max-width: 150px;
    font-size: 20px;
  }

  .ownerSectionUser {
    padding: 10px !important;
    font-size: 14px;
  }
}


/* Responsive for 1101px to 1270px */
@media (max-width: 1270px) and (min-width: 1101px) {
  .company-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 220px;
  }

  .company-logo {
    width: 28px;
    height: 28px;
  }

  .company-text {
    max-width: 180px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ownerSectionUser {
    padding: 4px !important;
    font-size: 15px;
  }
}


.custom-dropdown {
  background-color: black !important;
  /* Dropdown menu ka background black */
}

.custom-dropdown-item {
  color: white !important;
  /* Text white */
}

.custom-dropdown-item:hover {
  background-color: #333 !important;
  /* Hover effect ke liye dark gray */
}

.no-caret::after {
  display: none !important;
  /* ✅ Bootstrap caret arrow hide */
}

.download-buttons {
  display: flex;
  justify-content: space-between;
  /* Buttons evenly space hon */
  flex-wrap: wrap;
  /* Agar jagah kam ho to wrap kar sake */
  gap: 10px;
  /* Buttons ke darmiyan spacing */
}

.download-button {
  background-color: black;
  color: white;
  /* padding: 10px; */
  width: 32%;
  /* Teen buttons ek row me aayein */
  min-width: 120px;
  /* Bohat choti screens pr bhi show ho */
  font-size: 12px;
  /* Default font size */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile screens ke liye styles */
@media screen and (max-width: 768px) {
  .download-button {
    font-size: 12px;
    /* Mobile par text chhota ho */
    /* padding: 8px; */
  }
}

@media (max-width: 991px) {

  /* Mobile (sm, md) ke liye */
  .second-card {
    margin-left: 0 !important;
    /* Mobile screens pr margin remove */
    text-align: center;
  }
}

@media (min-width: 992px) {

  /* Large screens (lg, xl) ke liye */
  .second-card {
    margin-left: -8%;
  }
}


.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  /* background-color: #7ACB59; */
  color: '#7ACB59';
  border: none;
  padding: 3px 8px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  padding: 5px 0;
  top: 100%;
  left: 0;
}

.dropdown-content1 p {
  padding: 10px;
  margin: 0;
  cursor: pointer;
  color: #28659C;
  font-size: 14px;
}

.dropdown-content1 p:hover {
  background-color: #f1f1f1;
}

.dropdown1:hover .dropdown-content1 {
  display: block;
}

/* ✅ Parent Container: Ensures Single Row Layout */
.info-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  /* ✅ Space between elements */
  width: 100%;
  flex-wrap: nowrap;
  /* ✅ Prevents wrapping */
  overflow: hidden;
}

/* ✅ Break Time Container */
.break-time-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  /* ✅ Adjust Font Size */
  white-space: nowrap;
  /* ✅ Prevents new line */
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  /* ✅ Prevents shrinking */
  background-color: #1f4d7a;
  /* ✅ Match button bg */
  padding: 5px 10px;
  border-radius: 5px;
  color: #7ACB59;
  /* ✅ Text color */
  min-width: 80px;
  /* ✅ Ensures visibility */
  max-width: 100px;
  /* ✅ Prevents large expansion */
}

/* ✅ Company Name Container */
.company-name-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  /* ✅ Adjusted Font Size */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  /* ✅ Prevents shrinking */
  background-color: #1f4d7a;
  /* ✅ Match button bg */
  padding: 5px 10px;
  border-radius: 5px;
  color: #7ACB59;
  /* ✅ Text color */
  min-width: 100px;
  max-width: 150px;
}

.company-name-container1 {
  display: flex;
  align-items: center;
  font-size: 12px;
  /* ✅ Adjusted Font Size */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  /* ✅ Prevents shrinking */
  background-color: #1f4d7a;
  /* ✅ Match button bg */
  padding: 9px 5px;
  border-radius: 5px;
  color: #7ACB59;
  /* ✅ Text color */
  min-width: 100px;
  max-width: 150px;
}


/* ✅ Logo Styling */
.company-logo {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

/* ✅ Responsive Adjustments for Small Screens */
@media (max-width: 768px) {
  .info-container {
    gap: 5px;
    /* ✅ Reduce spacing */
  }

  .break-time-container {
    font-size: 10px;
    /* ✅ Smaller font size */
    padding: 4px 6px;
    min-width: 80px;
    max-width: 100px;

  }

  .company-name-container {
    font-size: 10px;
    /* ✅ Smaller font size */
    min-width: 80px;
    max-width: 100px;
  }

  .company-logo {
    width: 12px;
    height: 12px;
  }
}

.ownerSectionUser1.active {
  color: #7ACB59 !important;
  /* font-weight: bold; */
  font-size: 15px;
  border: none !important
}


.link-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  /* Default: no underline */
}

.link-button:hover {
  text-decoration: underline;
  /* On hover: underline */
}


@media (max-width: 767px) {
  .splash-card-body {
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  .splash-card-body {
    line-height: 1.6;
  }
}
